import * as React from "react";
import { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { axiosRequest, RequestType, successfulRequest } from "../../utils/network";
import { AxiosResponse } from "axios";

interface NSureSDK {
  getDeviceId: () => string;
}
interface DepositFormProps {
  setShowForm: (show: boolean) => void;
}

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
    maxWidth: "500px",
    margin: "0 auto",
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  label: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#4B5563",
  },
  inputContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
  },
  input: {
    padding: "14px 16px 14px 68px",
    border: "1px solid #d1d5db",
    borderRadius: "8px",
    fontSize: "16px",
    width: "100%",
    outline: "none",
    "&:focus": {
      borderColor: "#000",
      boxShadow: "0 0 0 2px rgba(0,0,0,0.1)",
    },
  },
  currencySymbol: {
    position: "absolute",
    left: "16px",
    fontSize: "16px",
    color: "#4B5563",
    pointerEvents: "none",
  },
  currencySelector: {
    position: "absolute",
    right: "16px",
    background: "rgba(0, 0, 0, 0.1)", // same 10% black
    border: "none",
    borderRadius: "4px",
    fontSize: "14px",
    fontWeight: 600,
    color: "#000", // slightly darker for visibility
    cursor: "pointer",
    padding: "4px 8px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.15)",
    },
    "&:focus": {
      outline: "none",
      borderColor: "#000",
    },
  },
  presetContainer: {
    display: "flex",
    gap: "8px",
    marginBottom: "24px",
    flexWrap: "wrap",
  },
  preset: {
    flex: 1, // grow to fill row
    minWidth: "80px",
    textAlign: "center",
    padding: "4px 0",
    borderRadius: "999px",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    backgroundColor: "rgba(0, 0, 0, 0.1)", // 10% black
    fontSize: "14px",
    cursor: "pointer",
    transition: "all 0.2s ease",
    fontWeight: 500,
    color: "#000",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.15)",
    },
    "&.active": {
      backgroundColor: "#000",
      color: "#fff",
      fontWeight: 600,
    },
  },
  button: {
    backgroundColor: "black",
    color: "white",
    padding: "16px",
    borderRadius: "999px",
    border: "none",
    fontSize: "14px",
    fontWeight: 700,
    textAlign: "center",
    width: "100%",
    cursor: "pointer",
    textTransform: "uppercase",
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: "#111",
      transform: "translateY(-1px)",
    },
    "&:disabled": {
      backgroundColor: "#6b7280",
      cursor: "not-allowed",
      transform: "none",
    },
    marginBottom: "24px",
  },
  backButton: {
    background: "none",
    border: "none",
    fontSize: "14px",
    color: "#6b7280",
    cursor: "pointer",
    padding: "0",
    display: "flex",
    alignItems: "center",
    gap: "4px",
    marginBottom: "16px",
    "&:hover": {
      color: "#4b5563",
      textDecoration: "underline",
    },
  },
  checkoutContainer: {
    position: "relative",
  },
  iframe: {
    width: "100%",
    height: "900px",
    border: "none",
    borderRadius: "12px",
    boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    marginBottom: "16px"
  },
  errorMessage: {
    color: "#EF4444",
    backgroundColor: "#FEF2F2",
    padding: "12px 16px",
    borderRadius: "8px",
    marginTop: "16px",
    marginBottom: "16px",
    fontSize: "14px",
    border: "1px solid #FEE2E2",
  },
}));

const DepositForm:React.FC<DepositFormProps> = ({ setShowForm }) => {
  const classes = useStyles();
  const [amount, setAmount] = useState<string>("");
  const [checkoutLink, setCheckoutLink] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [activePreset, setActivePreset] = useState<string | null>(null);

  const presets = ["10.0", "25.0", "50.0", "100.0"];

  const handleAmountChange = (value: string) => {
    // Allow only numbers and one decimal point
    if (value === "" || /^\d*\.?\d{0,2}$/.test(value)) {
      setAmount(value);
      
      // When manually typing an amount, check if it matches a preset
      if (presets.includes(value)) {
        // If the typed value exactly matches a preset, keep it active
        setActivePreset(value);
      } else {
        // Otherwise, we're typing a custom amount, clear active preset
        setActivePreset(null);
      }
    }
  };

  const formatAmount = (value: string): string => {
    if (!value) return "";
    
    // Convert to number and format with 2 decimal places
    const numValue = parseFloat(value);
    if (isNaN(numValue)) return value;
    
    // Only format if there's a decimal point
    if (value.includes(".")) {
      return numValue.toFixed(2);
    }
    return value;
  };

  const handlePresetClick = (presetAmount: string) => {
    setAmount(presetAmount);
    setActivePreset(presetAmount);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!amount || parseFloat(amount) <= 0) {
      setError("Please enter a valid amount");
      return;
    }

    setIsLoading(true);
    setError(null);
    
    try {
      const numericAmount = parseFloat(amount);
      const response = await axiosRequest(
        "/fiat/transactions", 
        { 
          amount: numericAmount,
          currency: "usd",
          device_id: (window as Window & { nSureSDK?: NSureSDK }).nSureSDK?.getDeviceId() || "",
        }, 
        RequestType.POST
      ) as AxiosResponse;
      
      if (successfulRequest(response)) {
        // Extract checkout link from metadata
        try {
          const transaction = response.data;
          const metadata = JSON.parse(transaction.metadata);
          const link = metadata.checkout_link;
          
          if (link) {
            setCheckoutLink(link);
          } else {
            setError("Checkout link not found in the response");
          }
        } catch (parseError) {
          setError("Error processing payment information");
        }
      } else {
        setError("Failed to create transaction");
      }
    } catch (_error) {
      setError("An unexpected error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  const handleBlur = () => {
    if (amount) {
      const formattedAmount = formatAmount(amount);
      setAmount(formattedAmount);
      
      // Check if the formatted amount matches a preset
      if (presets.includes(formattedAmount)) {
        setActivePreset(formattedAmount);
      } else {
        setActivePreset(null);
      }
    }
  };

  return (
    <div className={classes.container}>
      {!checkoutLink ? (
        <form onSubmit={handleSubmit}>
          {error && (
            <div className={classes.errorMessage}>
              {error}
            </div>
          )}
          <a className={classes.backButton} onClick={() => setShowForm(false)}>« Back</a>
          <div className={classes.formGroup}>
            <label htmlFor="amount" className={classes.label}>
              Amount
            </label>
            <div className={classes.inputContainer}>
              <span className={classes.currencySymbol}>USDC</span>
              <input
                id="amount"
                className={classes.input}
                value={amount}
                onChange={(e) => handleAmountChange(e.target.value)}
                onBlur={handleBlur}
                type="text"
                placeholder="0.00"
                required
                disabled={isLoading}
                autoComplete="off"
              />
            </div>
          </div>

          <div className={classes.presetContainer}>
            {presets.map((preset) => (
              <button
                key={preset}
                type="button"
                className={`${classes.preset} ${activePreset === preset ? 'active' : ''}`}
                onClick={() => handlePresetClick(preset)}
              >
                {preset}
              </button>
            ))}
          </div>

          <button 
            type="submit" 
            className={classes.button}
            disabled={isLoading || !amount}
          >
            {isLoading ? "Processing..." : "Continue to Payment"}
          </button>
        </form>
      ) : (
        <div className={classes.checkoutContainer}>
          <button 
            className={classes.backButton}
            onClick={() => setCheckoutLink(null)}
          >
            ← Edit Amount
          </button>
          
          <iframe 
            src={checkoutLink} 
            className={classes.iframe}
            title="Payment Checkout"
            allow="payment"
          />
        </div>
      )}
    </div>
  );
};

export default DepositForm;