import * as React from "react";
import makeStyles from "@mui/styles/makeStyles";
import DepositSelector from "./deposit_selector";
import DepositForm from "./deposit_form";

const useStyles = makeStyles(() => ({
  container: {
    marginTop: "20px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  depositTitle: {
    fontSize: "16px",
    fontWeight: 700,
  },
  cardButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "1px solid #d1d5db",
    borderRadius: "12px",
    padding: "16px 20px",
    marginBottom: "16px",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.03)",
      cursor: "pointer",
    },
  },
  leftContent: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },
  squarePlaceholder: {
    width: "24px",
    height: "24px",
    backgroundColor: "#ccc",
    borderRadius: "4px",
  },
  textGroup: {},
  title: {
    fontSize: "16px",
    fontWeight: 600,
    color: "black",
  },
  subtitle: {
    fontSize: "14px",
    color: "#6b7280", // Gray
  },
  arrowPlaceholder: {
    width: "16px",
    height: "16px",
    backgroundColor: "#ccc",
    borderRadius: "4px",
    flexShrink: 0,
  },
}));

const DepositContainer: React.FC = () => {
  const classes = useStyles();

  const [showForm, setShowForm] = React.useState(false);

  return (
    <div className={classes.container}>
        {showForm ? (<DepositForm setShowForm={setShowForm}/>) : (<DepositSelector setShowForm={setShowForm}/>)}
    </div>
  );
};

export default DepositContainer;
